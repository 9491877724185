
import * as requests from "./board_requests"
import { updateCursorIcon } from "../board_cursor";
import { end } from "@popperjs/core";
import { refreshModalContent } from "../navigation/slide_nav";

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });


base.handleClickingDoorOnMouseUp = function(options) {
  if(board.isErasingMode) { return }
  const door = options.target
  if(door == null || door.door != true) { return }
  var wall = door.associatedWall;
  if(wall == null || wall.doorState == null) { return }

  if(wall.doorState == 'locked') { 
    createTemporaryLockObject(wall)
    if(!board.currentUserIsGm) {
      requests.unlockAttempted(wall.uid);
    }
    if(board.isWallingMode || board.isLightingMode || board.isPinningMode || board.isDrawingMode || board.isRulerMode) {
      // kind of a hack to prevent other things from happening when you click on a locked door (but we dont want it in all cases so double click on door still works)
      board.mouseUpPerformed = true;
    }
  } else {
    var opened = false;
    if ( wall.doorState == 'open') {
      wall.set({ doorState: 'closed' });
    } else if ( wall.doorState == 'closed') {
      wall.set({ doorState: 'open' });
      opened = true;
    }
    function callback() {
      refreshModalContent([board.urlForObject(wall)]);
    }
    requests.updateRequest(wall, callback);
    requests.doorStateChanged(opened);
    board.updateVisibility();
    resizeDoorToWall(wall);
    board.renderAll();
    updateCursorIcon();
    board.mouseUpPerformed = true;
  }
}

export function createTemporaryLockObject(wall) {
  var temporaryLockObject = fabric.util.object.clone(wall.lockObject);
  var temporaryLockBackgroundObject = fabric.util.object.clone(wall.lockBackgroundObject);
  temporaryLockObject.set({ temporary: true, unlockAttempt: true, lock: null, showPlayers: true});
  temporaryLockBackgroundObject.set({ temporary: true, unlockAttempt: true, lock: null, showPlayers: true});
  board.addObject(temporaryLockBackgroundObject);
  board.addObject(temporaryLockObject);
  board.fadeRemoveTimer(temporaryLockObject);
  board.fadeRemoveTimer(temporaryLockBackgroundObject);
}

export function resizeDoorToWall(wall){
  if(wall.wall != true) { return }
  if(wall.type != 'line') { return }
  if(wall.doorObject == null) { return }

  var doorObject = wall.doorObject;
  var startX = wall.x1;
  var startY = wall.y1;
  var endX = wall.x2;
  var endY = wall.y2;
  if(wall.pivotSide == 'side_2') {
    startX = wall.x2;
    startY = wall.y2;
    endX = wall.x1;
    endY = wall.y1;
  }
  var doorHeight = 15;
  if(wall.doorSize == 'small') {
    doorHeight = 8;
  } else if (wall.doorSize == 'large') {
    doorHeight = 24;
  }

  const doorHeightOffset = doorHeight / 2;
  const lineAngleRadians = Math.atan2(endY - startY, endX - startX);
  const heightOffsetAngle = lineAngleRadians + Math.PI / 2;
  startX = startX - doorHeightOffset * Math.cos(heightOffsetAngle);
  startY = startY - doorHeightOffset * Math.sin(heightOffsetAngle);
  endX = endX + doorHeightOffset * Math.cos(heightOffsetAngle);
  endY = endY + doorHeightOffset * Math.sin(heightOffsetAngle);
  var lineAngleDegrees = fabric.util.radiansToDegrees(lineAngleRadians);
  var doorOpacity = 1;

  if(wall.doorState == 'open') {
    if(wall.openDirection == 'counter clockwise') {
      lineAngleDegrees = lineAngleDegrees - 70;
    } else {
      lineAngleDegrees = lineAngleDegrees + 70;
    }
    doorOpacity = 0.5;
  }

  doorObject.set({ 
    left: startX,
    top: startY,
    width: (new fabric.Point(startX, startY).distanceFrom(new fabric.Point(endX, endY))),
    height: doorHeight,
    angle: lineAngleDegrees,
    fill: wall.doorColor,
    opacity: doorOpacity,
  });
  doorObject.setCoords({ top: startY, left: startX });

  if(wall.lockObject != null) {
    const middleX = (startX + endX) / 2;
    const middleY = (startY + endY) / 2;
    wall.lockObject.set({ top: middleY, left: middleX });
    wall.lockObject.setCoords({ top: middleY, left: middleX });
    wall.lockBackgroundObject.set({ top: middleY, left: middleX });
    wall.lockBackgroundObject.setCoords({ top: middleY, left: middleX });
  }
}

function removeDoorObjects(wall) {
  if(wall.doorObject != null) { 
    board.remove(wall.doorObject);
    wall.doorObject = null;
  }
  if(wall.lockObject != null) {
    board.remove(wall.lockObject);
    wall.lockObject = null;
  }
  if(wall.lockBackgroundObject != null) {
    board.remove(wall.lockBackgroundObject);
    wall.lockBackgroundObject = null;
  }
}

function createDoorObjects(wall) {
  if(wall.wall != true) { return }
  if(wall.type != 'line') { return }
  if(wall.doorState == null) { return }

  var doorObject = new fabric.Rect({
    stroke: 'black',
    strokeWidth: 1,
    rx: 5,
    ry: 5,
    corderStyle: 'round',
    door: true,
    hoverCursor: 'pointer',
    playerClickable: true,
    associatedWall: wall,
  });

  wall.doorObject = doorObject;
  board.addObject(doorObject);

  if(wall.doorState == 'locked') {
    var lockBackgroundObject = new fabric.Circle({
      originX:'center', 
      originY:'center', 
      showPlayers: false,
      strokeWidth: 1,
      radius: 10,
      fill: '#ffffff',
      stroke: '#CD7F32',
      associatedWall: wall,
      lock: true,
    });
    wall.lockBackgroundObject = lockBackgroundObject;
    board.addObject(lockBackgroundObject);

    var path="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z";
    var lockObject = new fabric.Path(path, {
      originX:'center', 
      originY:'center', 
      showPlayers: false,
      associatedWall: wall,
      lock: true,
      fill: "#CD7F32"
    });    
    lockObject.scale(0.022);

    wall.lockObject = lockObject;
    board.addObject(lockObject);

  }

  resizeDoorToWall(wall)
}


export function resetDoorObject(wall)  {
  removeDoorObjects(wall);
  createDoorObjects(wall);
}

export function setDoorState(wall, state) {
  if(wall.wall != true && wall.type != 'line') { return }

  wall.set({ doorState: state })
  function callback() {
    refreshModalContent([board.urlForObject(wall)]);
  }
  resetDoorObject(wall);
  requests.updateRequest(wall, callback);
}

