import * as requests from "./board_requests"
import uniqueId from "../../lib/unique_id"
import { linesIntersectAt } from "./board_visibility_calculations"
import { resetDoorObject, resizeDoorToWall } from "./board_doors";
import { refreshModalContent } from "../navigation/slide_nav";


var board;
var base = fabric.Canvas.prototype;

const wallPointRadius = 8;
const wallPointAnimationChange = 6;
const wallPointAnimationDuration = 400;

var wallsToMoveSecondPoint = [];
var wallsToMoveFirstPoint = [];
var wallPointMoveStartPosition = {}


base.startWallingMode = function() {
  board.isWallingMode = true;
}

base.endWallingMode = function() {
  board.isWallingMode = false;
  board.lastWallPoint = null;
}

base.addWallPointListeners = function(wallPoint) {
  wallPoint.on('mousedown', wallPointOnMouseDown);
  wallPoint.on('moving', wallPointOnMove);
  wallPoint.on('moved', wallPointMoved);
}

const lastWallPoint = function() {
  if (board.lastWallPoint == null) { return }
  return board.getObjects().find(element => element.uid == (board.lastWallPoint || {} ).uid)
}

const wallPointAnimations = function(step) {
  if(step == 'grow') {
    return { 'radius': wallPointRadius + wallPointAnimationChange }
  } else {
    return { 'radius': wallPointRadius }
  }
}
const nextAnimationStep = {
  "grow": "shrink",
  "shrink": "grow"
}

const animateWallPoint = function(wallPoint, step) {
  if(!board.isWallingMode) { return }
  if(lastWallPoint() == null || lastWallPoint().uid != wallPoint.uid) { 
    wallPoint.set({ 'radius': wallPointRadius });
    board.renderAll();
    return;
  }
  

  wallPoint.animate(wallPointAnimations(step), {
    duration: wallPointAnimationDuration,
    onChange: board.renderAll.bind(board),
    onComplete: function() {
      animateWallPoint(wallPoint, nextAnimationStep[step]);
    }
  });
}

const closeEnoughTo = function(x1, y1, x2, y2) {
  return x1 + 7 > x2 && x1 - 7 < x2 && y1 + 7 > y2 && y1 - 7 < y2
}

export function linesForWallPoint(wallPoint) {
  var endingInLines = [];
  var startingInLines = [];
  board.getObjects().forEach(function(object) {
    if (object.type == 'line' && object.wall != null && object.x1 == wallPoint.left && object.y1 == wallPoint.top) {
      startingInLines.push(object);
    } else if (object.type == 'line' && object.wall != null && object.x2 == wallPoint.left && object.y2 == wallPoint.top) {
      endingInLines.push(object);
    }
  })
  return [startingInLines, endingInLines];
}

function updateLinesToWallPointLocation(startingInLines, endingInLines, wallPoint) {
  startingInLines.forEach(function(line) {
    line.set({'x1': wallPoint.left, 'y1': wallPoint.top })
    resizeDoorToWall(line);
  })
  endingInLines.forEach(function(line) {
    line.set({'x2': wallPoint.left, 'y2': wallPoint.top })
    resizeDoorToWall(line);
  })
}

const validWallPointMove = function() {
  var allWalls = board.getObjects().filter(object => object.wall == true && object.type == 'line' );
  const testingWalls = wallsToMoveFirstPoint.concat(wallsToMoveSecondPoint);
  var intersectionFound = false;
  testingWalls.forEach(function(testingWall) {
    allWalls.forEach(function(wall) {
      if(testingWalls.includes(wall)) { return }
      if(intersectionFound == true) { return }

      if(linesIntersectAt(testingWall, wall) != null) {
        intersectionFound = true;
      }
    })
  })
  return !intersectionFound;
}

function wallPointOnMouseDown() {
  var wallPoint = this;
  wallPointMoveStartPosition = { left: wallPoint.left, top: wallPoint.top };

  [wallsToMoveFirstPoint, wallsToMoveSecondPoint ] = linesForWallPoint(wallPoint);
}


function wallPointOnMove() {
  var wallPoint = this;
  board.lastWallPoint = null;
  restrictWallPointToBoard(wallPoint);

  // always round wallpoint location to avoid accidental rounding on the backend
  wallPoint.set({ left: Math.round(wallPoint.left), top: Math.round(wallPoint.top) })
  updateLinesToWallPointLocation(wallsToMoveFirstPoint, wallsToMoveSecondPoint, wallPoint);
}

function wallPointMoved(options) {
  var wallPoint = options.target;
  board.lastWallPoint = null;
  if(validWallPointMove()) {
    combineOverlappingWallPoints(wallPoint);
    // these have to be called after the combine method incase the walls move in that
    wallsToMoveFirstPoint.forEach(requests.updateRequest);
    wallsToMoveSecondPoint.forEach(requests.updateRequest);
  } else {
    // for some reason setCoords must be called below as well, otherwise the selectable area on the wall point is not updated
    wallPoint.set({ top: wallPointMoveStartPosition.top, left: wallPointMoveStartPosition.left });
    wallPoint.setCoords({ top: wallPointMoveStartPosition.top, left: wallPointMoveStartPosition.left });
    updateLinesToWallPointLocation(wallsToMoveFirstPoint, wallsToMoveSecondPoint, wallPoint);
  }
  board.updateVisibility();
}

const restrictWallPointToBoard = function(wallPoint) {
  if (wallPoint.left > board.mapWidth) { wallPoint.left = board.mapWidth }
  if (wallPoint.left < 0 ) { wallPoint.left = 0 }
  if (wallPoint.top > board.mapHeight) { wallPoint.top = board.mapHeight }
  if (wallPoint.top < 0 ) { wallPoint.top = 0 }
}

const newWallPoint = function(left, top) {
  
  var wallPoint = new fabric.Circle({
    left: left,
    top: top,
    strokeWidth: 0,
    radius: wallPointRadius,
    fill: 'red',
    stroke: 'red',
    originX: 'center',
    originY: 'center',
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,  
    hasBorders: false,
    hasControls: false,
    wall: true,
    uid: uniqueId()
  });
  restrictWallPointToBoard(wallPoint);
  board.addWallPointListeners(wallPoint)
  return wallPoint
}

const newWallLine = function(startX, startY, endX, endY) {
  return new fabric.Line([startX, startY, endX, endY], {
    fill: 'red',
    stroke: 'red',
    strokeWidth: 1,
    wall: true,
    uid: uniqueId(),
  });
}

const isWallPointSelected = function() {
  return (board.getActiveObject() != null && board.getActiveObject() != null && board.getActiveObject().type == 'circle')
}

const isOverlappingWallPoint = function(wallPoint, object) {
  return object.type == 'circle' && object.wall != null && closeEnoughTo(wallPoint.left, wallPoint.top, object.left, object.top) && object.uid != wallPoint.uid
}

const isRemovableWall = function(object) {
  return object.type == 'line' && object.wall != null && closeEnoughTo(object.x1, object.y1, object.x2, object.y2)
}

const combineOverlappingWallPoints = function(wallPoint) {
  board.getObjects().forEach(function(object) {
    if (isRemovableWall(object)) {
      board.remove(object)
      requests.destroyRequest([object.uid]); 
    } else if (isOverlappingWallPoint(wallPoint, object)) {
      // snap the current wall point to the old one's location (and update the lines)
      var [startingInLines, endingInLines] = linesForWallPoint(wallPoint);
      wallPoint.left = object.left;
      wallPoint.top = object.top;

      updateLinesToWallPointLocation(startingInLines, endingInLines, wallPoint);
      board.remove(object)
      requests.destroyRequest([object.uid]); 
    }
  })
}

const addWallBetween = function(pointA, pointB) {
  var newWall = newWallLine(pointA.left, pointA.top, pointB.left, pointB.top);
  board.addObject(newWall);
  requests.createRequest(newWall);
}

const checkForIntersectionsAndAddWallBetween = function(pointA, pointB) {
  var testingWall = { x1: pointA.left, y1: pointA.top, x2: pointB.left, y2: pointB.top }
  var allWalls = board.getObjects().filter(object => object.wall == true && object.type == 'line' );

  var intersections = false;
  allWalls.forEach (function(wall) {
    const intersection = linesIntersectAt(testingWall, wall);
    if(intersection != null) {
      intersections = true;
      var intersectionWallPoint = newWallPoint(Math.round(intersection.x), Math.round(intersection.y))
      board.addObject(intersectionWallPoint);
      requests.createRequest(intersectionWallPoint);

      addWallBetween(pointA, intersectionWallPoint);
      addWallBetween(intersectionWallPoint, pointB);

      addWallBetween({ left: wall.x1, top: wall.y1 }, intersectionWallPoint);
      addWallBetween(intersectionWallPoint, { left: wall.x2, top: wall.y2 });

      board.remove(wall); 
      requests.destroyRequest([wall.uid]);
    }
  });
  if(!intersections) {
    addWallBetween(pointA, pointB);
  }
}

base.handleWallClickOnMouseUp = function(options) {
  if(!board.isWallingMode ) { return }
  if(options.target != null && options.target.token != null) { return }
  var wallPoint;
  var deselectLastWallPoint = false;

  if (isWallPointSelected()) { 
    wallPoint = board.getActiveObject()
    if(lastWallPoint() != null) {
      deselectLastWallPoint = true;
    }
  } else {
    var wallPoint = newWallPoint(Math.round(options.absolutePointer.x), Math.round(options.absolutePointer.y))
    board.addObject(wallPoint);
    requests.createRequest(wallPoint);
    board.updateVisibility();
    board.clickCanBeUndone('wall');
  }

  if(lastWallPoint() != null && lastWallPoint() != wallPoint) {
    checkForIntersectionsAndAddWallBetween(lastWallPoint(), wallPoint)
    board.clickCanBeUndone('wall');
  }
  if(deselectLastWallPoint) {
    board.lastWallPoint = null;
  } else {
    board.lastWallPoint = wallPoint;
    animateWallPoint(wallPoint, "grow");
  }
  board.mouseUpPerformed = true;
}


export function setWallType(wall, wallType) {
  if(wall.wall != true && wall.type != 'line') { return }
  if(wallType == 'regular') {
    wall.set({ doorState: null, openDirection: null, doorColor: null, doorSize: null, pivotSide: null, transparent: false, stroke: 'red'})
  } else if (wallType == 'transparent') {
    wall.set({ doorState: null, openDirection: null, doorColor: null, doorSize: null, pivotSide: null, transparent: true, stroke: 'yellow'})
  } else if (wallType == 'door') {
    wall.set({ doorState: board.doorStateDefault, openDirection: board.doorOpenDirectionDefault, doorColor: board.doorColorDefault,  doorSize: board.doorSizeDefault, pivotSide: board.doorPivotSideDefault, transparent: false, stroke: 'red'})
  }
  function callback() {
    refreshModalContent([board.urlForObject(wall)]);
  }
  resetDoorObject(wall);
  requests.updateRequest(wall, callback);
  board.updateVisibility();
}


$(document).on('change', '.wall-type-select', function(event) {
  const uid = $(this).data('boardObjectUid');
  var wall = board.findObject(uid);
  $('.loading-indicator').removeClass('d-none');
  setWallType(wall, $(this).val());
})


$( document ).on('board:created', function(event, b) { board = b });

