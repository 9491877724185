import consumer from "./consumer"
import { handleNewContentData } from "../custom/handle_ajax_response"

$( document ).on('user_channel_id_set', function(event, userId) {
  consumer.subscriptions.create({ 
    channel: "UserChannel",
    user_id: userId,
    }, {
    received(data) {
      if(data.user_board_updates != null) {
        var board = window.__board;
        board.set(data.user_board_updates);
        // These fields need to be set on the brush not on the board
        if (data.user_board_updates.highlighterWidth != null) {
          board.freeDrawingBrush.width = data.user_board_updates.highlighterWidth;
        }
        if (data.user_board_updates.highlighterColor != null) {
          board.freeDrawingBrush.color = data.user_board_updates.highlighterColor;
        }
      }
      handleNewContentData(data);
    }
  });
});
